import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  PageLayout,
  ThumbnailText,
  PageHeading,
  TwoColumnSection,
  RenderVideoAndImage,
  Quiz,
  QuizModal,
  DietitianArea,
  DieteticsServiceArea,
  DietitianBookingsModal,
  TrainerSection,
} from 'components';
import { graphql } from 'gatsby';
import { ServicePageType } from 'types';
import {
  showNutritionPageQuizPrevQuestion,
  showNutritionPageQuizNextQuestion,
  nutritionPageQuizModalOpen,
  nutritionPageQuizModalClose,
  submitNutritionPageQuizAndPreviewAnswers,
  updateNutritionPageQuizPreviewStatus,
  updateNutritionPageQuizSummaryReviewedStatus,
  resetNutritionPageQuiz,
} from 'Redux/actions';
// import ReactMarkdown from 'react-markdown';

const ServicePage: FC<ServicePageType> = ({ data, pageContext }: any) => {
  const {
    NutritionPageQuizReducer: {
      currentQuestion,
      submitedQuestions,
      showQuizSummary,
      isQuizSummaryReviewed,
    },
    QuizModalReducer: { isNutritionPageQuizModalOpen },
  } = useSelector((state: any) => {
    return state;
  });
  const VideoGallery: any = [];
  const {
    ServicePage: {
      title,
      ServiceVideoImage,
      pageTheme,
      tagline,
      description,
      banner,
      // secondDescription,
      service,
      slug: strapiServicePageSlug,
      ServiceTitleAndText,
      seo,
      meetDietticianContent,
      dieteticsSpecialtyAreas,
      meetPersonalTrainerContent,
    },
  } = data;
  const { NutritionQuizSection, slug, servicePageBanner } = data.ServicesPage;
  const dispatch = useDispatch();
  const handleModalOpen = () => {
    if (!isNutritionPageQuizModalOpen) {
      dispatch(nutritionPageQuizModalOpen());
      window.history.pushState('', '', `/${slug}/nutrition/quiz`);
    }
  };
  const handleModalclose = () => {
    dispatch(nutritionPageQuizModalClose());
    window.history.pushState('', '', `/${slug}/nutrition`);
  };
  useEffect(() => {
    if (
      window.location.pathname === pageContext.quizslug ||
      window.location.pathname === `${pageContext.quizslug}/`
    ) {
      handleModalOpen();
    }
    VideoGallery.push(ServiceVideoImage.video?.localFile.publicURL);
  }, [pageContext.quizslug, VideoGallery]);

  const [DietitianModalOpen, setDietitianModalOpen] = useState(false);
  const handleDietitianModalOpen = () => {
    setDietitianModalOpen(true);
  };

  return (
    <>
      <>
        <PageLayout
          title={title}
          metaTitle={seo?.metaTitle ?? title}
          description={seo?.metaDescription}
          image={seo?.shareImage?.localFile?.publicURL}
          className={`${pageTheme}`}
          bannerTitle={banner?.title || servicePageBanner.title}
          bannerTagline={banner?.tagline || servicePageBanner.tagline}
          bannerImage={banner?.image || servicePageBanner.image}
          bannerButton={banner?.ctaButton || servicePageBanner.ctaButton}
          bannerPlaceholder={
            banner?.postcodePlaceholder || servicePageBanner.postcodePlaceholder
          }
        >
          <div className="container">
            {ServiceVideoImage && strapiServicePageSlug !== 'nutrition' && (
              <PageHeading
                title={title}
                subtitle={tagline}
                description={description}
                level={1}
              />
            )}
          </div>
          {strapiServicePageSlug === 'nutrition' && (
            <div>
              <div className="container">
                {/* <div className="page-heading-section">
                  <PageHeading title={title} subtitle={tagline} level={1} />
                  <div className="nutrition-page-content">
                    <div className="nutrition-page-description">
                      <ReactMarkdown className="md-html">
                        {description}
                      </ReactMarkdown>
                    </div>
                    <div className="nutrition-page-summary">
                      <ReactMarkdown className="md-html">
                        {secondDescription}
                      </ReactMarkdown>
                    </div>
                  </div>
                </div> */}
                <DietitianBookingsModal
                  DietitianModalOpen={DietitianModalOpen}
                  setDietitianModalOpen={setDietitianModalOpen}
                  link="https://get-going.au3.cliniko.com/bookings?business_id=700133283232488984&embedded=true"
                />
                <DietitianArea
                  dieteticsSpecialtyAreas={dieteticsSpecialtyAreas}
                />
              </div>
              <DieteticsServiceArea
                dieteticsarea={data.DieteticsServiceArea}
                dieteticservice={data.DieteticsService}
                dietitian={data.Dietitian}
                meetdietitiancontent={meetDietticianContent}
                pathname={`/${slug}/${strapiServicePageSlug}`}
                dietitianLink={data.DieticianLink.dietitianLink}
                dietitianBooking={`/${slug}/${strapiServicePageSlug}/${data.DietitianBookings.slug}`}
                handleDietitianModalOpen={handleDietitianModalOpen}
              />
              <RenderVideoAndImage
                data={ServiceVideoImage}
                altText={title}
                hasPlayButton
                hasBorder
                buttontype="text"
                buttonsize="large"
                videogallery={VideoGallery}
                showControls
                showModal
              />
            </div>
          )}
          <div className="container">
            {service && (
              <div className="section-wrapper">
                <ThumbnailText
                  services={service}
                  servicePageSlug={strapiServicePageSlug}
                  parentSlug={slug}
                />
              </div>
            )}
          </div>
          {strapiServicePageSlug === 'fitness' && (
            <TrainerSection
              personalTrainer={data.Trainer.personal_trainers}
              sectionHeading={meetPersonalTrainerContent}
            />
          )}
          {ServiceVideoImage && strapiServicePageSlug !== 'nutrition' && (
            <RenderVideoAndImage
              data={ServiceVideoImage}
              altText={title}
              hasPlayButton
              hasBorder
              buttontype="text"
              buttonsize="large"
              videogallery={VideoGallery}
              showControls
              showModal
            />
          )}
          {ServiceTitleAndText && (
            <div className="container">
              <div className="section-wrapper">
                <TwoColumnSection
                  title={ServiceTitleAndText.title}
                  content={ServiceTitleAndText.content}
                />
              </div>
            </div>
          )}
          <div className="container">
            {strapiServicePageSlug === 'nutrition' && (
              <Quiz
                QuizWizardData={NutritionQuizSection}
                handelQuizModalOpen={handleModalOpen}
              />
            )}
          </div>
        </PageLayout>
        <QuizModal
          isQuizModalOpen={isNutritionPageQuizModalOpen}
          portalClassName={`${pageTheme} nutrition-page-quiz-modal`}
          handelQuizModal={handleModalclose}
          QuizQuestions={data.NutritionPageQuizQuestions}
          currentQuestion={currentQuestion}
          submitedQuestions={submitedQuestions}
          showPrevQuestion={showNutritionPageQuizPrevQuestion}
          showNextQuestion={showNutritionPageQuizNextQuestion}
          previewAnswer={submitNutritionPageQuizAndPreviewAnswers}
          showQuizSummary={showQuizSummary}
          toogleQuizPreview={updateNutritionPageQuizPreviewStatus}
          isQuizSummaryReviewed={isQuizSummaryReviewed}
          updateQuizSummaryReviewedStatus={
            updateNutritionPageQuizSummaryReviewedStatus
          }
          resetQuiz={resetNutritionPageQuiz}
          formSuccessMessage={NutritionQuizSection.formSuccessMessage}
          formSuccessTitle={NutritionQuizSection.formSuccessTitle}
          dealTitle="Nutrition Quiz"
        />
      </>
    </>
  );
};

export default ServicePage;

export const query = graphql`
  query ($id: String!) {
    ServicePage: strapiServicePage(id: { eq: $id }) {
      title
      slug
      tagline
      description: pageDescription
      secondDescription
      pageTheme
      banner {
        ctaButton
        id
        postcodePlaceholder
        tagline
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      seo {
        metaDescription
        metaTitle
        shareImage {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 400, height: 400)
            }
          }
        }
      }
      service {
        id
        title
        content
        servicePage: service_page {
          slug
          pageType
        }
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 300, height: 300)
            }
          }
        }
      }
      ServiceTitleAndText {
        content
        id
        title
      }
      ServiceVideoImage {
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
        }
      }
      meetDietticianContent {
        content
        tagline
        title
      }
      meetPersonalTrainerContent {
        title
        tagline
        content
        seeAllText
      }
      dieteticsSpecialtyAreas {
        title
        tagline
        content
        dietitianButtonText
        dietitianLink
        dieteticIcon {
          icon
          title
        }
      }
    }
    ServicesPage: strapiServicesPage {
      slug
      title
      servicePageBanner: banner {
        ctaButton
        id
        postcodePlaceholder
        tagline
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      NutritionQuizSection {
        title
        tagline
        quizAction {
          title
          description
        }
        quizStep {
          title
          icon
        }
        quizCTA {
          title
          description
          buttonText
        }
        formSuccessMessage
        formSuccessTitle
      }
    }
    NutritionPageQuizQuestions: allStrapiQuestions(
      filter: { questionCategory: { eq: "nutritionPageQuizQuestion" } }
    ) {
      edges {
        node {
          id
          questionOrderNumber
          question
          questionType
          description
          likertScaleQuestionOptions {
            id
            optiontitle
            description
            scaleMaxLabel
            scaleMinLabel
            likertScaleOptions {
              optionSummaryContent
              summary
              id
            }
          }
          multipleChoiceQuestionTypeOptions {
            id
            value
            optionSummaryContent
          }
          TextQuestionTypeOption {
            id
            value
            optionSummaryContent
            placeholder
            type
          }
        }
      }
    }
    DieteticsServiceArea: allStrapiDieteticsServiceArea {
      edges {
        node {
          title
          description
          dietetics_services {
            title
            overview
            description
            slug
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 518, height: 518)
                }
              }
            }
          }
        }
      }
    }
    DieteticsService: allStrapiDieteticsService {
      edges {
        node {
          title
          dietitians {
            title
          }
        }
      }
    }
    Dietitian: allStrapiDietitian {
      edges {
        node {
          slug
          title
          description
          qualification
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 520, height: 520)
              }
            }
          }
        }
      }
    }
    DieticianLink: strapiGlobal {
      dietitianLink {
        bookDietitianButtonText
        bookDietitianLink
        chatWithDietitianButtonText
        chatWithDietitianLink
        dietitianAppointmentButtonText
        dietitianAppointmentLink
        meetDietitianButtonText
        meetDietitianLink
      }
    }
    DietitianBookings: strapiDietitianBookingsPage {
      slug
    }
    Trainer: strapiPersonalTrainerPage {
      personal_trainers {
        qualification
        name
        slug
        description
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 520, height: 520)
            }
          }
        }
      }
    }
  }
`;
